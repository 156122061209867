
export const environment = {
  production: 'false',
  BUILD_ENV: 'dev',
  API_URL: 'https://devapi.safetk.app/',
  APP_INSIGHTS_KEY: '139e4845-e932-4b5d-a18c-8133b042ea26',
  AZURE_STORAGE_HOSTNAME: 'https://vmstg1901storage.blob.core.windows.net',
  AZURE_CONTAINER_NAME: 'test-uploads',
  SIGNALR_URL: 'https://devapi.safetk.app/signalr'
};
